import {
  UserDataInfo
} from '@/plugins/api'
import moment from 'moment'

const userData = new UserDataInfo();
export default class EventModel {
  constructor(item) {
    if (item) {
      const eventRdv = [
        this.owner_id = userData.ownerId(),
        this.reminder = item.reminder ? item.reminder : {
          active: false,
          count: 1,
          type: 'day',
          days: {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false
          }
        },
        this._type = item._type ? item._type : null,
        this.name = item.name ? item.name : null,
        this.date = item.date ? moment(item.date).toDate() : new Date(),
        this.__participants_id = item.__participants_id ? item.__participants_id : null,
        this.duration = item.duration ? item.duration : null,
        this.icon = item.icon ? item.icon : null,
        this.__contact_id = item.__contact_id ? item.__contact_id : [],
        this.__employee_id = item.__employee_id ? item.__employee_id : null,
        this.service_id = item.service_id ? item.service_id : null,
        this.expires_at = item.expires_at ? moment(item.expires_at).toDate() : null
      ];
      if (item._type == 'Rdv') {
        [...eventRdv];
        this._status = item._status ? item._status : 'Active';
      } else {
        [...eventRdv];
        this.description = item.description ? item.description : null;
        this._status = item._status ? item._status : null;
        this.agenda_id = item.agenda_id ? item.agenda_id : null;
        this.address = item.address ? item.address : null;
        this._status = item._status ? item._status : null,
          this.repeat = item.repeat ? item.repeat : null;
        this.importance = item.importance ? item.importance : 'lower';
        this.files = item.files ? item.files : [];
        this.category = item.category ? item.category : 'category';
        this.group_id = item.group_id ? item.group_id : null;
        this.document_id = item.document_id ? item.document_id : null;
      }
    }
  }
}