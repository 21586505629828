<template>
  <div class="p-grid" v-if="event">
    <div class="p-col-12">
      <TopBarBreadCrumb
        v-if="breadcrumbItems"
        :breadcrumbItems="breadcrumbItems"
        :title="`${i18n.$t('My')} ${event._type}`"
      />
      <form ref="eventForm">
        <div class="p-grid">
          <div class="p-col-6">
            <div class="form-input">
              <label>{{ i18n.$t('Name') }}</label>
              <InputGroup
                :text="true"
                :invalid="errors.name"
                :validation="valid.name"
              >
                <InputText
                  type="text"
                  name="name"
                  class="border border-teal-500 px-2 py-1 rounded"
                  v-model="event.name"
                  @blur="(valid.name = true), validation()"
                />
              </InputGroup>
            </div>

            <div v-if="event._type != 'Rdv'">
              <div class="form-input p-mt-3">
                <label for="time24">{{ i18n.$t('Date') }}</label>
                <InputGroup
                  :text="true"
                  :invalid="errors.date"
                  :validation="valid.date"
                >
                  <Calendar
                    id="time24"
                    v-model="event.date"
                    :showTime="true"
                    :showSeconds="false"
                    @blur="(valid.date = true), validation()"
                    @date-select="(valid.date = true), validation()"
                    :minDate="new Date()"
                  />
                </InputGroup>
              </div>
              <!-- 
            <div class="form-input p-mt-3">
              <label>{{ i18n.$t('Duration') }}</label>
              <InputText type="text" name="duration" v-model="event.duration" />
            </div>
 -->

              <div class="form-input p-mt-3">
                <label>{{ i18n.$t('Expire at') }} </label>
                <Calendar
                  id="expires_at"
                  v-model="event.expires_at"
                  :showTime="true"
                  :showSeconds="false"
                  @blur="(valid.expires_at = true), validation()"
                  @date-select="(valid.expires_at = true), validation()"
                  :minDate="new Date()"
                />
              </div>

              <div class="form-input p-mt-3">
                <label>{{ i18n.$t('Remind') }}</label>
                <reminder :reminder="event.reminder" />
              </div>
            </div>
            <!-- <div v-if="event._type == 'Rdv'" class="p-mt-3">
              <label>{{ i18n.$t('Date') }}/{{ i18n.$t('Time') }}</label>
              <rdv-slots :event="event" />
            </div> -->

            
            <!-- <div class="form-input p-mt-3" v-if="!type">
              <label>{{ i18n.$t('Select Agenda') }}</label>
              <InputGroup
                :text="true"
                :invalid="errors.agenda_id"
                :validation="valid.agenda_id"
              >
                <MultiSelect
                  name="agenda"
                  v-model="event.agenda_id"
                  :options="agendas"
                  optionLabel="name"
                  optionValue="_id"
                  @change="(valid.agenda_id = true), validation()"
                />
              </InputGroup>
            </div> -->
          </div>
          <div class="p-col-6">
            <!-- <div class="form-input">
              <label>{{ i18n.$t('Repeat') }}</label>
              <InputText
                type="number"
                name="repeat"
                class="border border-teal-500 px-2 py-1 rounded"
                min="1"
                v-model="event.repeat"
              />
            </div> -->
            <div class="form-input p-mt-3">
              <label>{{ i18n.$t('Description') }}</label>
              <QuillEditor
                toolbar="minimal"
                v-model:content="event.description"
                :content="event.description"
                contentType="html"
                @blur="(valid.description = true), validation()"
              />
            </div>

            <div v-if="event._type !== 'Task' && false" class="form-input p-mt-3">
              <label>{{ i18n.$t('Address') }}</label>
              <InputGroup
                :text="true"
                :invalid="errors.address"
                :validation="valid.address"
                @click="showAddress = true"
              >
                <InputText
                  type="text"
                  v-model="event.address"
                  name="address"
                  class="p-pr-5"
                />
                <div class="overlay"></div>
                <i class="pi pi-arrow-right" v-if="!valid.address"></i>
              </InputGroup>
            </div>

            <div
              v-if="event._type !== 'Task' && showAddress "
              class="form-input p-mt-1 p-mb-3"
            >
              <SelectAddress
                :country="country"
                :address="address"
                @selected="
                  (event.address = $event), (valid.address = true), validation()
                "
              />
            </div>

            <!-- <div class="form-input p-mt-2" v-if="!type">
              <label>{{ i18n.$t('Select Contact') }}</label>
              
              <MultiSelect
                name="contact"
                v-model="event.__contact_id"
                :options="contacts"
                optionLabel="first_name"
                optionValue="_id"
              />
            </div> -->

            <!-- <div class="form-input p-mt-2" v-if="!type">
              <label>{{ i18n.$t('Select Group') }}</label>
              
              <MultiSelect
                name="group"
                v-model="event.group_id"
                :options="groups"
                optionLabel="name"
                optionValue="_id"
              />
            </div> -->

            <div class="form-input p-mt-3">
              <label>{{ i18n.$t('Importance') }}</label>
              <div class="p-formgroup-inline">
                <div class="radio-input p-mt-1 p-mr-4">
                  <RadioButton
                    id="lower"
                    name="importance"
                    value="lower"
                    v-model="event.importance"
                  />
                  <label for="lower">{{ i18n.$t('importance.Lower') }}</label>
                </div>
                <div class="radio-input p-mt-1 p-mr-4">
                  <RadioButton
                    id="normal"
                    name="importance"
                    value="normal"
                    v-model="event.importance"
                  />
                  <label for="normal">{{ i18n.$t('importance.Normal') }}</label>
                </div>
                <div class="radio-input p-mt-1 p-mr-4">
                  <RadioButton
                    id="high"
                    name="importance"
                    value="high"
                    v-model="event.importance"
                  />
                  <label for="high">{{ i18n.$t('importance.High') }}</label>
                </div>
              </div>
            </div>

            <!-- <div class="form-input p-mt-3" v-if="!type">
              <div class="form-input p-mt-3">
                <label for="files">{{ i18n.$t('Document') }}</label>
                <div class="d-flex">
                  <InputGroup
                    :text="true"
                    :invalid="errors.document_id"
                    :validation="valid.document_id"
                    style="width: 80%"
                  >
                    <MultiSelect
                      name="service"
                      v-model="event.document_id"
                      :options="documents"
                      optionLabel="name"
                      optionValue="_id"
                      required
                      @change="(valid.document_id = true), validation()"
                    />
                  </InputGroup>

                  <Button
                    type="button"
                    class="p-ml-2"
                    @click="showDocumentModal = true"
                  >
                    {{ i18n.$t('Add document') }}
                  </Button>
                </div>
              </div>
            </div> -->
            <!-- 
            <div class="form-input p-mt-3">
              <label>{{ i18n.$t('Icon') }} </label>
              <Dropdown
                name="icon"
                v-model="event.icon"
                :options="icons"
                optionLabel="label"
                optionValue="value"
              />
            </div> -->
          </div>
        </div>
        <div class="p-mt-4">
          <Button
            type="button"
            class="font-semibold loading-btn p-button-success p-px-4"
            @click="validation, saveEvent()"
          >
            <spinner v-if="isLoading" color="white" />
            <span v-if="!isLoading">Save Event</span>
          </Button>
        </div>
      </form>

      <Dialog
        header="Add Document"
        v-model:visible="showDocumentModal"
        :style="{ width: '50vw' }"
        :modal="true"
      >
        <AddDocumentModal @onuploaddocument="onUploadDocument" />
      </Dialog>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import Spinner from '@/components/UI/Spinner.vue';
import TopBarBreadCrumb from '../Utilities/TopBarBreadCrumb';
import SelectAddress from '../Register/SelectAddress';
import useI18n from '@/plugins/useI18n';
import { QuillEditor } from '@vueup/vue-quill';

import {
  GET_CONTACTS,
  FETCH_CONTACTS,
  FETCH_GROUPS,
  GET_GROUPS,
  GET_AGENDA,
  FETCH_AGENDA,
  GET_DOCUMENTS,
  GET_USERDATA
} from '@/store/types';
import FileUpload from 'primevue/fileupload';
import * as yup from 'yup';
import AddDocumentModal from '../Information/AddDocumentModal.vue';
import InputGroup from '../../components/UI/InputGroup';
import axios from '@/plugins/axios';
import Reminder from './Reminder.vue';

export default {
  components: {
    TopBarBreadCrumb,
    Spinner,
    FileUpload,
    AddDocumentModal,
    SelectAddress,
    QuillEditor,
    InputGroup,
    Reminder
  },
  props: {
    breadcrumbItems: {
      type: Object
    },
    event: {
      type: Object
    },
    message: {
      type: Object
    },
    type: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);

    const documents = computed(() => store.getters[GET_DOCUMENTS]);
    const contacts = computed(() => store.getters[GET_CONTACTS]);
    const agendas = computed(() => store.getters[GET_AGENDA]);
    const groups = computed(() => store.getters[GET_GROUPS]);
    const primevue = usePrimeVue();
    primevue.config.locale.dateFormat = 'dd/mm/yy';

    const icons = [
      { label: 'Icon1', value: 'icon1' },
      { label: 'Icon2', value: 'icon2' },
      { label: 'Icon3', value: 'icon3' }
    ];

    const showDocumentModal = ref(false);

    const showAddress = ref(false);

    const address = reactive({
      addressReq: '',
      fullAddress: ''
    });

    //console.log(props.event);

    const country = ref('');

    const uploadDisabled = ref(false);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const contactData =
      contacts &&
      contacts.value &&
      contacts.value.map((i, k) => {
        return i._id;
      });

    props.event.__participants_id = contactData;

    if (!contacts.value) {
      store.dispatch(FETCH_CONTACTS, userData.value.id);
    }
    if (!agendas.value) {
      store.dispatch(FETCH_AGENDA, userData.value.id);
    }
    if (!groups.value) {
      store.dispatch(FETCH_GROUPS, userData.value.id);
    }

    const uploadLabel = ref('Upload');

    const pageName = ref();
    const eventForm = ref();

    watch(
      () => [route.query],
      () => {
        loadType();
      }
    );

    loadType();

    function loadType() {
      const query = route.query;
      props.event._type = query && query._type ? query._type : 'Task';
      pageName.value = query && query._type ? query._type : 'Event';
    }

    const fullFields = computed(
      () => !props.type && route.query._type !== 'Task'
    );

    const valid = ref({
      name: false,
      description: false,
      date: false
    });

    if (fullFields.value) {
      valid.value['address'] = false;
    }

    if (!props.type) {
      valid.value['agenda_id'] = false;
    }

    let schema = yup.object().shape({
      name: yup.string().min(3),
      agenda_id: !props.type ? yup.array().min(1) : null,
      description: yup.string().min(5),
      date: yup.date(),
      address: fullFields.value ? yup.string() : null
    });

    const errors = ref({});

    let eventDataValue = ref(null);

    function saveEvent() {
      // if(errors.eventDataValue){

      emit('save', eventDataValue);
      // }
    }

    async function validation(click) {
      eventDataValue = props.event;
      schema
        .validate(
          {
            name: eventDataValue.name,
            agenda_id: !props.type ? eventDataValue.agenda_id : null,
            description: eventDataValue.description,
            date: eventDataValue.date,
            document_id: eventDataValue.document_id,
            address: fullFields.eventDataValue ? eventDataValue.address : null
          },
          { abortEarly: false }
        )
        .then(() => {
          errors.eventDataValue = {};
          if (click) {
            emit('save');
          }
        })
        .catch((err) => {
          errors.eventDataValue = {};
          err.inner.forEach((el) => {
            errors.eventDataValue[el.path] = true;
          });
          if (click) {
            for (let key in valid.eventDataValue) {
              valid.eventDataValue[key] = true;
            }
          }
        });
    }

    return {
      i18n,
      eventForm,
      icons,
      pageName,
      isLoading,
      contacts,
      groups,
      uploadDisabled,
      uploadLabel,
      showDocumentModal,
      documents,
      agendas,
      validation,
      valid,
      errors,
      address,
      country,
      showAddress,
      saveEvent,
      eventDataValue
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.address-fields) {
  .font-semibold {
    font-weight: 400;
  }
  input {
    width: 100%;
  }
}
.p-datepicker {
  min-width: auto !important;
}
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  position: relative;
  label {
    margin-bottom: 0.25rem;
  }
}
.full-width-form {
  .form-input {
    max-width: unset;
  }
}
.loading-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.radio-input label {
  margin-left: 10px;
}
.file-wrapper {
  position: relative;
  text-align: center;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.file-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.tui-datepicker-input {
  width: 100%;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  height: 32px;
}
.error-msg {
  display: none;
}
.p-error {
  display: block;
}
</style>
