<template>
  <div>
    <InputSwitch v-model="reminder.active" :disabled="!change" />
  </div>
  <div v-if="reminder.active">
    <div class="p-d-flex p-ai-center p-mb-2">
      {{ i18n.$t('Repeat every') }}
      <InputNumber
        :disabled="!change"
        class="p-mx-2"
        id="withoutgrouping"
        showButtons
        v-model="reminder.count"
        :useGrouping="false"
      />

      <Dropdown
        :disabled="!change"
        v-model="reminder.type"
        :options="types"
        optionLabel="label"
        optionValue="value"
      />
    </div>
    <div class="" v-if="showDays">
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[0]"
        :onLabel="days[0]"
        :offLabel="days[0]"
      />
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[1]"
        :onLabel="days[1]"
        :offLabel="days[1]"
      />
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[2]"
        :onLabel="days[2]"
        :offLabel="days[2]"
      />
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[3]"
        :onLabel="days[3]"
        :offLabel="days[3]"
      />
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[4]"
        :onLabel="days[4]"
        :offLabel="days[4]"
      />
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[5]"
        :onLabel="days[5]"
        :offLabel="days[5]"
      />
      <ToggleButton
        class="p-button-rounded p-mr-1"
        :disabled="!change"
        v-model="reminder.days[6]"
        :onLabel="days[6]"
        :offLabel="days[6]"
      />
    </div>
  </div>
</template>

<script>
import ToggleButton from 'primevue/togglebutton';
import { computed, ref } from 'vue';
import { useI18n } from '../../plugins/i18nPlugin';
export default {
  components: {
    ToggleButton
  },
  props: {
    change: {
      type: Boolean,
      default: true
    },
    reminder: {
      type: Object,
      default: {
        active: false,
        count: 1,
        type: 'day',
        days: {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false
        }
      }
    }
  },
  setup(props) {
    const i18n = useI18n();
    const showDays = computed(() => {
      return props.reminder.type == 'week';
    });

    const types = [
      {
        label: i18n.$t('day'),
        value: 'day'
      },
      {
        label: i18n.$t('week'),
        value: 'week'
      },
      {
        label: i18n.$t('month'),
        value: 'month'
      },
      {
        label: i18n.$t('year'),
        value: 'year'
      }
    ];
    const days = {
      0: i18n.$t('shortDays')[0],
      1: i18n.$t('shortDays')[1],
      2: i18n.$t('shortDays')[2],
      3: i18n.$t('shortDays')[3],
      4: i18n.$t('shortDays')[4],
      5: i18n.$t('shortDays')[5],
      6: i18n.$t('shortDays')[6]
    };

    return { i18n, days, types, showDays };
  }
};
</script>

<style>
</style>